"use strict";

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var app = {
  setCookie: function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  },
  getCookie: function getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  },
  mobileMenu: function mobileMenu() {
    var mobileBtn = $("#mobile-burger-btn");
    var pageBody = $("#page-wrap-inner");

    if (mobileBtn.length > 0) {
      // Load Menu
      mobileBtn.click(function () {
        if (mobileBtn.hasClass("menu-open") == true) {
          pageBody.removeClass("push");
          mobileBtn.removeClass("menu-open");
        } else {
          pageBody.addClass("push");
          mobileBtn.addClass("menu-open");
        }
      });
    }
  },
  mobileMenuSub: function mobileMenuSub() {
    var parent = $("#mobile-nav-items .mid ul a.hasChildren");

    parent.click(function (e) {
      e.preventDefault();
      var topLevel = $(this).parent("li");
      var subMenu = $(topLevel).find(".subMenu");
      if (subMenu.hasClass("hidden")) {
        $(".subMenu").addClass("hidden");
        subMenu.removeClass("hidden");
      } else {
        subMenu.addClass("hidden");
      }
      //subMenu.removeClass("hidden");
    });
  },
  megaMenu: function megaMenu() {
    var parent = $("header nav a.hasChildren");

    parent.click(function (e) {
      e.preventDefault();
      var topLevel = $(this).parent("li");
      var subMenu = $(topLevel).find(".subMenu");
      var subMenuArrow = $(topLevel).find(".active-arrow");
      if (subMenu.hasClass("hidden")) {
        $(".subMenu").addClass("hidden");
        $(".active-arrow").addClass("hidden");
        subMenu.removeClass("hidden");
        subMenuArrow.removeClass("hidden");
      } else {
        subMenu.addClass("hidden");
        subMenuArrow.addClass("hidden");
      }
      //subMenu.removeClass("hidden");
    });

    // close menu whenever clicked outside of it
    $(document).on("click", function (e) {
      var container = $('header nav');
      var width = $(window).width();
      //check if the clicked area is in menu or not
      if (container.has(e.target).length === 0 && width > 960) {
        $(".subMenu").addClass("hidden");
        $(".active-arrow").addClass("hidden");
      }
    });
  },
  validateEmail: function validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  },

  initTabs: function initTabs() {
    var tabs = $('#tabs-btns span');
    var tabContent = $('#tabs-content .tab-wrap-content-inner');

    $.each(tabContent, function (key, row) {
      var row = $(row);
      row.attr('data-tab-content', key);
      row.addClass('hidden');
    });

    $.each(tabs, function (key, row) {
      var row = $(row);
      row.attr('data-tab-btn', key);
      row.click(function () {
        var selected = $(this);
        tabs.removeClass('selected');
        selected.addClass('selected');
        tabContent.addClass('hidden');
        $('#tabs-content .tab-wrap-content-inner[data-tab-content=' + key + ']').removeClass('hidden').fadeIn();
      });

      if (key == 0) {
        row.trigger('click');
      }
    });
  },
  autocompleteSeachBar: function autocompleteSeachBar() {
    var input = document.getElementById('search-autocomplete');

    new google.maps.places.Autocomplete(input, {
      componentRestrictions: { country: 'uk' }
    });
  },
  fancyboxEnquiry: function fancyboxEnquiry() {
    $('.fancybox-enquiry-frm').click(function (e) {
      e.preventDefault();
      $.fancybox.open({
        src: '#fancybox-frm-wrapper',
        type: 'inline',
        opts: {
          afterLoad: function afterLoad() {
            $('#submit_enquiry_btn').click(function (e) {
              e.preventDefault();
              var form = $('#fancybox-frm');
              var preloader = $('#frm-loader');
              var _success = $('#frm-success');
              var _error = $('#frm-error');
              preloader.removeClass('hidden');
              $.ajax({
                url: form.attr('action'),
                type: form.attr('method'),
                data: form.serialize(),
                beforeSend: function beforeSend(xhr) {
                  xhr.setRequestHeader('X-CSRF-Token', form.find('input[name="_token"]').val());
                },
                success: function success(data) {
                  preloader.addClass('hidden');
                  if (data.success == true) {
                    //form.css('opacity', 0);
                    form.addClass('hidden');
                    _success.removeClass('hidden');
                    _error.addClass('hidden');
                  } else {
                    _error.removeClass('hidden');
                    _success.addClass('hidden');
                  }
                },
                error: function error(data) {
                  var errors_msg = data.responseJSON.errors;
                  var html = "";
                  $(errors_msg).each(function (key, row) {
                    html += row + "<br />";
                  });
                  if (html != '') {
                    _error.html(html);
                  }
                  preloader.addClass('hidden');
                  _error.removeClass('hidden');
                  _success.addClass('hidden');
                }
              });
            });
          }
        }
      });
    });
  },

  searchFilter: function searchFilter() {
    if ($('#search_child').length > 0) {
      // COPY ALL OPTIONS
      var all_options = $('select#search_child').html();
      $('body').append('<div id="search_child_all" class="hidden">' + all_options + '</div>');
    }

    // FILTER BASED ON PARENT
    $("#search_parent").change(function () {
      var filter = $(this).val();
      $('select#search_child').empty();
      // TYPE PLACEHOLDER IF BEING FILTERED
      if (filter != '') {
        $('select#search_child').html('<option id="" value="" data-placeholder>Type:</option>');
      }
      $('#search_child_all option').each(function () {
        var selected = $(this);
        var attr = selected.attr('data-title');
        if (filter == '') {
          // SHOW ALL
          var val = selected.val();
          var text = selected.text();
          $('select#search_child').append('<option value="' + val + '" data-title="' + attr + '">' + text + '</option>');
        } else {
          // FILTER BASED OF DATA ATTR
          if (attr == filter) {
            var val = selected.val();
            var text = selected.text();
            $('select#search_child').append('<option value="' + val + '" data-title="' + attr + '">' + text + '</option>');
          }
        }
      });
    });
  },
  hiddenfrm: function hiddenfrm() {
    $(".opt-yes").click(function () {
      console.log('clicked');
      $("#blk-" + $(this).val()).show('fast');
    });
    $(".opt-no").click(function () {
      console.log('clicked');
      $('.toHide').hide();
      $("#blk-" + $(this).val()).show('fast');
    });
  },

  privacyNotification: function privacyNotification() {
    var privacy = $('#privacyNotification');
    var privacyBtn = $('.notificationToggle');
    var exists = app.getCookie('privacy');

    if (exists != 1) {
      $('.privacy-area').removeClass('hidden');
    } else {
      app.setCookie('privacy', 1, 60);
    }

    privacyBtn.click(function (e) {
      $('.privacy-area').toggleClass('hidden');
      e.preventDefault();
      app.setCookie('privacy', 1, 60);
    });
  },

  init: function init() {
    var _$$slick, _$$slick2;

    app.mobileMenu();
    app.megaMenu();
    app.mobileMenuSub();

    easydropdown.all({
      behavior: {
        liveUpdates: true
      }
    });

    if ($('#privacyNotification').length > 0) {
      app.privacyNotification();
    }

    if ($('#search-autocomplete').length > 0) {
      app.autocompleteSeachBar();
    }

    if ($('#search_parent').length > 0) {
      app.searchFilter();
    }

    if ($('.fancybox-enquiry-frm').length > 0) {
      app.fancyboxEnquiry();
    }

    if ($('#tabs').length > 0) {
      app.initTabs();
    }

    if ($("#blk-Yes").length > 0) {
      app.hiddenfrm();
    }

    $("#banners").slick((_$$slick = {
      appendDots: "#bannerpaging",
      dots: true,
      infinite: true,
      speed: 500,
      arrows: false,
      slidesToShow: 1
    }, _defineProperty(_$$slick, "slidesToShow", 1), _defineProperty(_$$slick, "fade", true), _defineProperty(_$$slick, "cssEase", "linear"), _defineProperty(_$$slick, "autoplay", true), _defineProperty(_$$slick, "autoplaySpeed", 5000), _defineProperty(_$$slick, "pauseOnHover", false), _$$slick));

    $("#slick-case-studies").slick((_$$slick2 = {
      appendDots: "#testimonialpaging",
      dots: true,
      infinite: true,
      speed: 300,
      slidesToShow: 1,
      cssEase: "linear",
      centerMode: true,
      centerPadding: "20%"
    }, _defineProperty(_$$slick2, "slidesToShow", 1), _defineProperty(_$$slick2, "nextArrow", "#nxt-btn"), _defineProperty(_$$slick2, "prevArrow", "#prev-btn"), _defineProperty(_$$slick2, "responsive", [{
      breakpoint: 1300,
      settings: {
        centerPadding: "18%"
      }
    }, {
      breakpoint: 900,
      settings: {
        centerPadding: "6%"
      }
    }, {
      breakpoint: 600,
      settings: {
        centerPadding: "1.5%"
      }
    }]), _$$slick2));
  }
};

$(document).ready(function () {
  var $tabs = $('#horizontalTab');
  $tabs.responsiveTabs({
    rotate: false,
    startCollapsed: 'accordion',
    collapsible: 'accordion',
    setHash: true,
    click: function click(e, tab) {
      $('.info').html('Tab <strong>' + tab.id + '</strong> clicked!');
    },
    activate: function activate(e, tab) {
      $('.info').html('Tab <strong>' + tab.id + '</strong> activated!');
    },
    activateState: function activateState(e, state) {
      //console.log(state);
      $('.info').html('Switched from <strong>' + state.oldState + '</strong> state to <strong>' + state.newState + '</strong> state!');
    }
  });
});

$(document).ready(function () {
  app.init();
});